<template>
  <div v-if="model">
    <img :src="imagePath" class="news-bg" alt="Haber" />
    <img :src="imagePath" class="news-bg bg-blur" alt="Haber" />
    <div class="newsMain">
      <div class="news-title wow fadeInDown" data-wow-delay="0.3s">
        <strong>Sağlık</strong>
      </div>
      <div class="news-text wow fadeInUp" data-wow-delay="1.2s">
        <span>{{ model.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wow.js';
import store from '../store/index';
new WOW().init();
export default {
  name: 'news-health',
  props: ['data'],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.news;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.model.imageUrl}`;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/animate/animate.css';
@import '../assets/scss/news.scss';

.newsMain {
  .news-title {
    background-image: url('/images/bg/news/news-health-title.png');
  }

  .news-text {
    background-image: url('/images/bg/news/news-health-text.png');
  }
}
</style>
